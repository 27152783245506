<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        a) Consider a manual bicycle pump with an initial internal volume of
        <number-value :value="vol1" unit="\text{L.}" />
        Calculate the work done on the internal gas of the pump when it is compressed using a
        constant pressure of
        <number-value :value="pressure" unit="\text{atm}" />
        to a final value of
        <number-value :value="vol2" unit="\text{L.}" />
        You may assume that the air inside the bicycle pump is behaving as an ideal gas.
      </p>

      <calculation-input
        v-model="inputs.workLAtm"
        class="mb-4"
        prepend-text="$\text{Work:}$"
        append-text="$\text{L}\cdot\text{atm}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) Convert your answer from part a) to units of Joules (J). Note that
        <stemble-latex content="$1\,\text{L}\cdot\text{atm} = 101.325\,\text{J.}$" />
      </p>

      <calculation-input
        v-model="inputs.workJ"
        prepend-text="$\text{Work:}$"
        append-text="$\text{J}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question393',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        workLAtm: null,
        workJ: null,
      },
    };
  },
  computed: {
    vol1() {
      return this.taskState.getValueBySymbol('vol1').content;
    },
    vol2() {
      return this.taskState.getValueBySymbol('vol2').content;
    },
    pressure() {
      return this.taskState.getValueBySymbol('pressure').content;
    },
  },
};
</script>
